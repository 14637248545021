cookies {
    border-top: 1px solid var(--Gray30);
    clear: both;
    box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.05);
    background-color: white;
    z-index: 9;

    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
}
