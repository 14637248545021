#CookiesDialog {
    .close-x {
        text-align: right;
    }
    .botonera {
        text-align: center;
        button {
            width: 100%;
        }
    }
    .custom-control-input:checked~.custom-control-label::before {
        color: #fff;
        border-color: var(--GreenEECI70) !important;
        background-color: var(--GreenEECI70) !important;
    }    
}
